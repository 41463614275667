import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="prose mx-auto text-prose-xl">
      <p>{`Battery Park
Collage, ink and watercolor on rice paper
24”x36”x1”`}</p>
      <p>{`Look closely to see walkways and docks along the NY shoreline. Lively drawing among a riot of sunset colors.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/d2602/IMG_1677.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECA//EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABJTD0ILP/xAAbEAABBAMAAAAAAAAAAAAAAAAAAQIQERIhIv/aAAgBAQABBQLJx2WsWbP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwGI/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwGba//EABgQAQADAQAAAAAAAAAAAAAAAAAQITER/9oACAEBAAY/AtjXFqf/xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhYTFBUXGB/9oACAEBAAE/IQAA6DiYEVsoFUA7ELTC+Z4QPaKf/9oADAMBAAIAAwAAABAQ3//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQMBAT8QB2dX/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAECAQE/EIrh/8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFBUYH/2gAIAQEAAT8QKDCkiGrxipazo3xpjzjbhnqurPmSx0NNeZBtL0z/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "photo",
              "title": "photo",
              "src": "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/4b190/IMG_1677.jpg",
              "srcSet": ["/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/e07e9/IMG_1677.jpg 200w", "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/066f9/IMG_1677.jpg 400w", "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/4b190/IMG_1677.jpg 800w", "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/e5166/IMG_1677.jpg 1200w", "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/b17f8/IMG_1677.jpg 1600w", "/static/fd2a7ec05bb5e3260d2860c3d3f1cbb7/d2602/IMG_1677.jpg 4032w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><img parentName="p" {...{
          "src": "./IMG_1678.jpg",
          "alt": "photo"
        }}></img></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      